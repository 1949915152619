<template>
  <div>
    <h3>LA8</h3>
    <div>
      <a href="https://www.cinod.fr/wordpress/wp-content/old-lacoustics/LA8_OM_EN.pdf" target="_blank" >   
      <img src="@/assets/img-amplis/la8-face.jpg" alt="" />
      <img src="@/assets/img-amplis/la8.jpg" alt="" />
        
      </a>
    </div>

    Librairie de presets LA8

Librairie de presets LA8 6.12
<h3>K1</h3>
011 [K1] K1, large bande
<h3>K2</h3>
012 [K2 70] K2, large bande, réglage 70º sur ailettes mobiles<br/>
013 [K2 90] K2, large bande, réglage 90º sur ailettes mobiles<br/>
014 [K2 110] K2, large bande, réglage 110º sur ailettes mobiles<br/>
<h3>K3</h3>
015 [K3 70] K3, large bande, réglage 70º sur ailettes mobiles<br/>
016 [K3 90] K3, large bande, réglage 90º sur ailettes mobiles<br/>
017 [K3 110] K3, large bande, réglage 110º sur ailettes mobiles<br/>
<h3>K1-SB</h3>
018 [K1SB_60] K1-SB, LPF=60 Hz, optimisé pour la conguration CONTOUR<br/>
019 [K1SB_X] K1-SB, LPF=200 Hz, optimisé pour la conguration THROW sur K1<br/>
020 [K1SB_X K2] K1-SB, LPF=200 Hz, optimisé pour la conguration THROW sur K2<br/>
<h3>V-DOSC</h3>
021 [V-DOSC_LO] V-DOSC, large bande, contour LO<br/>
022 [V-DOSC_LO_60] V-DOSC, HPF=60 Hz, contour LO<br/>
023 [V-DOSC_LO_X] V-DOSC, large bande, contour LO, optimisé pour les presets [SB218_X] & [dV-S_X]<br/>
024 [V-DOSC_HI] V-DOSC, large bande, contour HI<br/>
025 [V-DOSC_HI_60] V-DOSC, HPF=60 Hz, contour HI<br/>
026 [V-DOSC_HI_X] V-DOSC, large bande, contour HI, optimisé pour les presets [SB218_X] & [dV-S_X]
<h3>KUDO</h3>
027 [KUDO50_25] Kudo, HPF=25 Hz, réglage 50º sur K-Louver<br/>
028 [KUDO50_40] Kudo, HPF=40 Hz, réglage 50º sur K-Louver<br/>
029 [KUDO50_60] Kudo, HPF=60 Hz, réglage 50º sur K-Louver<br/>
030 [KUDO80_25] Kudo, HPF=25 Hz, réglage 80º sur K-Louver<br/>
031 [KUDO80_40] Kudo, HPF=40 Hz, réglage 80º sur K-Louver<br/>
032 [KUDO80_60] Kudo, HPF=60 Hz, réglage 80º sur K-Louver<br/>
033 [KUDO110_25] Kudo, HPF=25 Hz, réglage 110º sur K-Louver<br/>
034 [KUDO110_40] Kudo, HPF=40 Hz, réglage 110º sur K-Louver<br/>
035 [KUDO110_60] Kudo, HPF=60 Hz, réglage 110º sur K-Louver<br/>
<h3>KARA_II</h3>
036 [KARA II 70] Kara II, large bande, réglage 70º sur ailettes mobiles<br/>
037 [KARA II 90] Kara II, large bande, réglage 90º sur ailettes mobiles<br/><br/>
038 [KARA II 110] Kara II, large bande, réglage 110º sur ailettes mobiles<br/>
039 [KARA II_FI] Kara II, HPF=100 Hz, complément (fill)<br/>
040 [KARA II_MO] Kara II, large bande, retour de scène (monitor), basse latence<br/>
041 [KARAIIDOWNK1] Kara II, délai optimisé en complément downll pour K1<br/>
042 [KARAIIDOWNK2] Kara II, délai optimisé en complément downll pour K2<br/>
043 [KARAIIDOWNK3] Kara II, délai optimisé en complément downll pour K3<br/>
<h3>KARA</h3>
044 [KARA] Kara, large bande, façade (FOH)<br/>
045 [KARA_FI] Kara, HPF=100 Hz, complément (fill)<br/>
046 [KARADOWNK1] Kara, HPF=100 Hz, délai optimisé en complément downll pour K1<br/>
047 [KARADOWNK2] Kara, HPF=100 Hz, délai optimisé en complément downll pour K2<br/>
048 [KARADOWNK3] Kara, HPF=100 Hz, délai optimisé en complément downll pour K3<br/>
<h3>dV-DOSC</h3>
049 [dV_FI] dV-DOSC, HPF=100 Hz, complément (fill)<br/>
050 [dV_LO] dV-DOSC, large bande, contour LO<br/>
051 [dV_LO_100] dV-DOSC, HPF=100 Hz, contour LO<br/>
052 [dV_HI] dV-DOSC, large bande, contour HI<br/>
053 [dV_HI_100] dV-DOSC, HPF=100 Hz, contour HI<br/>
<h3>dV-D_dVS</h3>
054 [dV_dV-S_LO] dV-DOSC & dV-SUB, X-OVER=100 Hz, contour LO<br/>
055 [dV_dV-S_HI] dV-DOSC & dV-SUB, X-OVER=100 Hz, contour HI<br/>
056 [dV_dV-S_LO60] dV-DOSC & dV-SUB, HPF=60 Hz, X-OVER=100 Hz, contour LO<br/>
057 [dV_dV-S_HI60] dV-DOSC & dV-SUB, HPF=60 Hz, X-OVER=100 Hz, contour HI<br/>
<h3>dV-SUB</h3>
058 [dV-S_60_100] dV-SUB, HPF=60 Hz, LPF=100 Hz<br/>
059 [dV-S_100] dV-SUB, LPF=100 Hz<br/>
060 [dV-S_60_X] dV-SUB, HPF=60 Hz, LPF=200 Hz, optimisé pour les presets [V-DOSC_xx_60]<br/>
061 [dV-S_X] dV-SUB, LPF=200 Hz, optimisé pour les presets [V-DOSC_xx_X]
<h3>ARCS_II</h3>
062 [ARCS II] ARCS II, large bande
<h3>ARCS</h3>
063 [ARCS_LO] ARCS, large bande, contour LO<br/>
064 [ARCS_LO_60] ARCS, HPF=60 Hz, contour LO<br/>
065 [ARCS_LO_100] ARCS, HPF=100 Hz, contour LO<br/>
066 [ARCS_HI] ARCS, large bande, contour HI<br/>
067 [ARCS_HI_60] ARCS, HPF=60 Hz, contour HI<br/>
068 [ARCS_HI_100] ARCS, HPF=100 Hz, contour HI<br/>
<h3>A15</h3>
069 [A15] A15 Wide ou A15 Focus, large bande<br/>
070 [A15_FI] A15 Wide ou A15 Focus, large bande, complément (fill)<br/>
071 [A15_MO] A15 Wide ou A15 Focus, large bande, retour de scène (monitor), basse latence<br/>
<h3>A10</h3>
072 [A10] A10 Wide ou A10 Focus, large bande<br/>
073 [A10_FI] A10 Wide ou A10 Focus, large bande, complément (fill)<br/>
074 [A10_MO] A10 Wide ou A10 Focus, large bande, retour de scène (monitor), basse latence<br/>
<h3>ARCS_WF</h3>
075 [ARCS_WIFO] ARCS Wide ou ARCS Focus, large bande, façade (FOH)<br/>
076 [ARCS_WIFO_FI] ARCS Wide ou ARCS Focus, large bande, complément (fill)<br/>
<h3>SB28</h3>
077 [SB28_60] SB28, LPF=60 Hz<br/>
078 [SB28_100] SB28, LPF=100 Hz<br/>
079 [SB28_60_C] SB28, LPF=60 Hz, cardioïde<br/>
080 [SB28_100_C] SB28, LPF=100 Hz, cardioïde<br/>
081 [SB28_60_Cx] SB28, LPF=60 Hz, cardioïde étendu<br/>
082 [SB28_100_Cx] SB28, LPF=100 Hz, cardioïde étendu<br/>
<h3>KS21</h3>
083 [KS21_60] KS21, LPF=60 Hz<br/>
084 [KS21_100] KS21, LPF=100 Hz<br/>
085 [KS21_60_C] KS21, LPF=60 Hz, cardioïde<br/>
086 [KS21_100_C] KS21, LPF=100 Hz, cardioïde<br/>
087 [KS21_60_Cx] KS21, LPF=60 Hz, cardioïde étendu<br/>
088 [KS21_100_Cx] KS21, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB218</h3>
089 [SB218_60] SB218, LPF=60 Hz<br/>
090 [SB218_100] SB218, LPF=100 Hz<br/>
091 [SB218_X] SB218, LPF=200 Hz, optimisé pour les presets [V-DOSC_xx_X]<br/>
<h3>SB18</h3>
092 [SB18_60] SB18, LPF=60 Hz<br/>
093 [SB18_100] SB18, LPF=100 Hz<br/>
094 [SB18_60_C] SB18, LPF=60 Hz, cardioïde<br/>
095 [SB18_100_C] SB18, LPF=100 Hz, cardioïde<br/>
096 [SB18_60_Cx] SB18, LPF=60 Hz, cardioïde étendu<br/>
097 [SB18_100_Cx] SB18, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB118</h3>
098 [SB118_60] SB118, LPF=60 Hz<br/>
099 [SB118_100] SB118, LPF=100 Hz<br/>
100 [SB118_60_C] SB118, LPF=60 Hz, cardioïde<br/>
101 [SB118_100_C] SB118, LPF=100 Hz, cardioïde<br/>
<h3>SB15</h3>
102 [SB15_100] SB15m, LPF=100 Hz<br/>
103 [SB15_100_C] SB15m, LPF=100 Hz, cardioïde<br/>
104 [SB15_100_Cx] SB15m, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB10</h3>
105 [SB10_60] SB10i, LPF=60 Hz<br/>
106 [SB10_100] SB10i, LPF=100 Hz<br/>
107 [SB10_200] SB10i, LPF=200 Hz<br/>
<h3>KILO</h3>
108 [KILO] Kilo, LPF=100 Hz<br/>
<h3>KIVA_II</h3>
109 [KIVA II] Kiva II, large bande, façade (FOH)<br/>
110 [KIVA II_FI] Kiva II, large bande, complément (fill)<br/>
<h3>KIVA</h3>
111 [KIVA] Kiva, large bande, façade (FOH)<br/>
112 [KIVA_FI] Kiva, large bande, complément (fill)<br/>
<h3>SB15KIVA</h3>
113 [KIVA_SB15] Kiva & SB15m, X-OVER=100 Hz, large bande, façade (FOH)<br/>
<h3>KILOKIVA</h3>
114 [KIVA_KILO] Kiva & Kilo, large bande, X-OVER=100 Hz, façade (FOH)<br/>
<h3>SYVA</h3>
115 [SYVA] Syva, large bande
<h3>SYVA_LOW</h3>
116 [SYVA LOW_100] Syva Low (séparé), LPF=100 Hz<br/>
<h3>SYVA+LOW</h3>
117 [SYVA LOW SYVA] Syva & Syva Low (couplé)<br/>
<h3>SYVA_SUB</h3>
118 [SYVA SUB_100] Syva Sub, LPF=100 Hz<br/>
119 [SYVA SUB_200] Syva Sub, LPF=200 Hz, optimisé pour le preset [X4]<br/>
<h3>X15HiQ</h3>
120 [X15] X15 HiQ, large bande<br/>
121 [X15_MO] X15 HiQ, large bande, retour de scène (monitor), basse latence<br/>
<h3>X12</h3>
122 [X12] X12, large bande<br/>
123 [X12_MO] X12, large bande, retour de scène (monitor), basse latence<br/>
<h3>X8</h3>
124 [X8] X8, large bande<br/>
125 [X8_MO] X8, large bande, retour de scène (monitor), basse latence<br/>
<h3>115XTHiQ</h3>
126 [HiQ_FI] 115XT HiQ, large bande, complément (fill)<br/>
127 [HiQ_FI_100] 115XT HiQ, HPF=100 Hz, complément (fill)<br/>
128 [HiQ_FR] 115XT HiQ, large bande, façade (FOH)<br/>
129 [HiQ_FR_100] 115XT HiQ, HPF=100 Hz, façade (FOH)<br/>
130 [HiQ_MO] 115XT HiQ, large bande, retour de scène (monitor)<br/>
131 [HiQ_MO_100] 115XT HiQ, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>12XTA</h3>
132 [12XTA_FI] 12XT active, large bande, complément (fill)<br/>
133 [12XTA_FI_100] 12XT active, HPF=100 Hz, complément (fill)<br/>
134 [12XTA_FR] 12XT active, large bande, façade (FOH)<br/>
135 [12XTA_FR_100] 12XT active, HPF=100 Hz, façade (FOH)<br/>
136 [12XTA_MO] 12XT active, large bande, retour de scène (monitor)<br/>
137 [12XTA_MO_100] 12XT active, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>12XTP</h3>
138 [12XTP_FI] 12XT passive, large bande, complément (fill)<br/>
139 [12XTP_FI_100] 12XT passive, HPF=100 Hz, complément (fill)<br/>
140 [12XTP_FR] 12XT passive, large bande, façade (FOH)<br/>
141 [12XTP_FR_100] 12XT passive, HPF=100 Hz, façade (FOH)<br/>
142 [12XTP_MO] 12XT passive, large bande, retour de scène (monitor)<br/>
143 [12XTP_MO_100] 12XT passive, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>8XT</h3>
144 [8XT_FI] 8XT, large bande, complément (fill)<br/>
145 [8XT_FI_100] 8XT, HPF=100 Hz, complément (fill)<br/>
146 [8XT_FR] 8XT, large bande, façade (FOH)<br/>
147 [8XT_FR_100] 8XT, HPF=100 Hz, façade (FOH)<br/>
148 [8XT_MO] 8XT, large bande, retour de scène (monitor)<br/>
149 [8XT_MO_100] 8XT, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>5XT</h3>
150 [5XT] 5XT, large bande<br/>
151 [5XT_MO] 5XT, large bande, retour de scène (monitor), basse latence<br/>
<h3>X4</h3>
152 [X4] X4i, large bande<br/>
153 [X4_60] X4i, limite basse fréquence et SPL max abaissés, conguration sur mur avec renfort de
grave séparé<br/>
154 [X4_MO] X4i, large bande, retour de scène (monitor), basse latence<br/>

<h3>115XT</h3>
155 [115XT_FI] 115XT, large bande, complément (fill)<br/>
156 [115XT_FI_100] 115XT, HPF=100 Hz, complément (fill)<br/>
157 [115XT_FR] 115XT, large bande, façade (FOH)<br/>
158 [115XT_FR_100] 115XT, HPF=100 Hz, façade (FOH)<br/>
159 [115XT_MO] 115XT, large bande, retour de scène (monitor)<br/>
160 [115XT_MO_100] 115XT, HPF=100 Hz, retour de scène (monitor)
<h3>MTD115bA</h3>
161 [115bA_FI] MTD115b active, large bande, complément (fill)<br/>
162 [115bA_FI_100] MTD115b active, HPF=100 Hz, complément (fill)<br/>
163 [115bA_FR] MTD115b active, large bande, façade (FOH)<br/>
164 [115bA_FR_100] MTD115b active, HPF=100 Hz, façade (FOH)<br/>
165 [115bA_MO] MTD115b active, large bande, retour de scène (monitor)<br/>
166 [115bA_MO_100] MTD115b active, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>MTD115bP</h3>
167 [115bP_FI] MTD115b passive, large bande, complément (fill)<br/>
168 [115bP_FI_100] MTD115b passive, HPF=100 Hz, complément (fill)<br/>
169 [115bP_FR] MTD115b passive, large bande, façade (FOH)<br/>
170 [115bP_FR_100] MTD115b passive, HPF=100 Hz, façade (FOH)<br/>
171 [115bP_MO] MTD115b passive, large bande, retour de scène (monitor)<br/>
172 [115bP_MO_100] MTD115b passive, HPF=100 Hz, retour de scène (monitor)
<h3>112XT</h3>
173 [112XT_FI] 112XT, large bande, complément (fill)<br/>
174 [112XT_FI_100] 112XT, HPF=100 Hz, complément (fill)<br/>
175 [112XT_FR] 112XT, large bande, façade (FOH)<br/>
176 [112XT_FR_100] 112XT, HPF=100 Hz, façade (FOH)<br/>
177 [112XT_MO] 112XT, large bande, retour de scène (monitor)<br/>
178 [112XT_MO_100] 112XT, HPF=100 Hz, retour de scène (monitor)
<h3>MTD112b</h3>
179 [112b_FI] MTD112b, large bande, complément (fill)<br/>
180 [112b_FI_100] MTD112b, HPF=100 Hz, complément (fill)<br/>
181 [112b_FR] MTD112b, large bande, façade (FOH)<br/>
182 [112b_FR_100] MTD112b, HPF=100 Hz, façade (FOH)<br/>
183 [112b_MO] MTD112b, large bande, retour de scène (monitor)<br/>
184 [112b_MO_100] MTD112b, HPF=100 Hz, retour de scène (monitor)<br/>

<h3>MTD108a</h3>
185 [108a_FI] MTD108a, large bande, complément (fill)<br/>
186 [108a_FI_100] MTD108a, HPF=100 Hz, complément (fill)<br/>
187 [108a_FR] MTD108a, large bande, façade (FOH)<br/>
188 [108a_FR_100] MTD108a, HPF=100 Hz, façade (FOH)<br/>
189 [108a_MO] MTD108a, large bande, retour de scène (monitor)<br/>
190 [108a_MO_100] MTD108a, HPF=100 Hz, retour de scène (monitor)
FLAT<wbr/>
191 [FLAT_LA8] EQ réponse plate, protection minimisant les risques de saturation
    <h3>FLAT</h3>
    188 [FLAT_LA8] EQ réponse plate, protection minimisant les risques de
    saturation<br />
  </div>
</template>

<script>
export default {
  name: "La8Ampli"
};
</script>

<style>
@import "../../assets/style/stylepresets.css";
</style>
