<template>
  <div>
    <h3>LA4X</h3>
    <div>
      <a href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA4X/EN/Owner's%20Manual/LA4X_OM_EN.pdf"
              target="_blank" >              
      <img src="@/assets/img-amplis/la4x-face.jpg" alt="" />
      <img src="@/assets/img-amplis/la4x.jpg" alt="">
      </a>
    </div>

    <h3>K2</h3>
011 [K2 70] K2, large bande, réglage 70º sur ailettes mobiles<br/>
012 [K2 90] K2, large bande, réglage 90º sur ailettes mobiles<br/>
013 [K2 110] K2, large bande, réglage 110º sur ailettes mobiles<br/>
<h3>K3</h3>
014 [K3 70] K3, large bande, réglage 70º sur ailettes mobiles<br/>
015 [K3 90] K3, large bande, réglage 90º sur ailettes mobiles<br/>
016 [K3 110] K3, large bande, réglage 110º sur ailettes mobiles<br/>
<h3>KUDO</h3>
017 [KUDO50_25] Kudo, HPF=25 Hz, réglage 50º sur K-Louver<br/>
018 [KUDO50_40] Kudo, HPF=40 Hz, réglage 50º sur K-Louver<br/>
019 [KUDO50_60] Kudo, HPF=60 Hz, réglage 50º sur K-Louver<br/>
020 [KUDO80_25] Kudo, HPF=25 Hz, réglage 80º sur K-Louver<br/>
021 [KUDO80_40] Kudo, HPF=40 Hz, réglage 80º sur K-Louver<br/>
022 [KUDO80_60] Kudo, HPF=60 Hz, réglage 80º sur K-Louver<br/>
023 [KUDO110_25] Kudo, HPF=25 Hz, réglage 110º sur K-Louver<br/>
024 [KUDO110_40] Kudo, HPF=40 Hz, réglage 110º sur K-Louver<br/>
025 [KUDO110_60] Kudo, HPF=60 Hz, réglage 110º sur K-Louver<br/>
<h3>KARA_II</h3>
026 [KARA II 70] Kara II, large bande, réglage 70º sur ailettes mobiles<br/>
027 [KARA II 90] Kara II, large bande, réglage 90º sur ailettes mobiles<br/>
028 [KARA II 110] Kara II, large bande, réglage 110º sur ailettes mobiles<br/>
029 [KARA II_FI] Kara II, HPF=100 Hz, complément (fill)<br/>
030 [KARA II_MO] Kara II, large bande, retour de scène (monitor), basse latence<br/>
031 [KARAIIDOWNK1] Kara II, délai optimisé en complément downll pour K1<br/>
032 [KARAIIDOWNK2] Kara II, délai optimisé en complément downll pour K2<br/>
033 [KARAIIDOWNK3] Kara II, délai optimisé en complément downll pour K3<br/>

<h3>KARA</h3>
034 [KARA] Kara, large bande, façade (FOH)<br/>
035 [KARA_FI] Kara, HPF=100 Hz, complément (fill)<br/>
036 [KARADOWNK1] Kara, HPF=100 Hz, délai optimisé en complément downll pour K1<br/>
037 [KARADOWNK2] Kara, HPF=100 Hz, délai optimisé en complément downll pour K2<br/>
038 [KARADOWNK3] Kara, HPF=100 Hz, délai optimisé en complément downll pour K3<br/>
<h3>KIVA_II</h3>
039 [KIVA II] Kiva II, large bande, façade (FOH)<br/>
040 [KIVA II_FI] Kiva II, large bande, complément (fill)<br/>
<h3>KIVA</h3>
041 [KIVA] Kiva, large bande, façade (FOH)<br/>
042 [KIVA_FI] Kiva, large bande, complément (fill)<br/>
<h3>SB15KIVA</h3>
043 [KIVA_SB15] Kiva & SB15m, X-OVER=100 Hz, large bande, façade (FOH)<br/>
<h3>KILOKIVA</h3>
044 [KIVA_KILO] Kiva & Kilo, large bande, X-OVER=100 Hz, façade (FOH)<br/>
<h3>ARCS_II</h3>
045 [ARCS II] ARCS II, large bande
<h3>A15</h3>
046 [A15] A15 Wide ou A15 Focus, large bande
047 [A15_FI] A15 Wide ou A15 Focus, large bande, complément (fill)<br/>
048 [A15_MO] A15 Wide ou A15 Focus, large bande, retour de scène (monitor), basse latence<br/>
<h3>A10</h3>
049 [A10] A10 Wide ou A10 Focus, large bande<br/>
050 [A10_FI] A10 Wide ou A10 Focus, large bande, complément (fill)<br/>
051 [A10_MO] A10 Wide ou A10 Focus, large bande, retour de scène (monitor), basse latence<br/>
<h3>ARCS_WF</h3>
052 [ARCS_WIFO] ARCS Wide ou ARCS Focus, large bande, façade (FOH)<br/>
053 [ARCS_WIFO_FI] ARCS Wide ou ARCS Focus, large bande, complément (fill)<br/>

<h3>KS21</h3>
054 [KS21_60] KS21, LPF=60 Hz<br/>
055 [KS21_100] KS21, LPF=100 Hz<br/>
056 [KS21_60_C] KS21, LPF=60 Hz, cardioïde<br/>
057 [KS21_100_C] KS21, LPF=100 Hz, cardioïde<br/>
058 [KS21_60_Cx] KS21, LPF=60 Hz, cardioïde étendu<br/>
059 [KS21_100_Cx] KS21, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB18</h3>
060 [SB18_60] SB18, LPF=60 Hz<br/>
061 [SB18_100] SB18, LPF=100 Hz<br/>
062 [SB18_60_C] SB18, LPF=60 Hz, cardioïde<br/>
063 [SB18_100_C] SB18, LPF=100 Hz, cardioïde<br/>
064 [SB18_60_Cx] SB18, LPF=60 Hz, cardioïde étendu<br/>
065 [SB18_100_Cx] SB18, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB15</h3>
066 [SB15_100] SB15m, LPF=100 Hz<br/>
067 [SB15_100_C] SB15m, LPF=100 Hz, cardioïde<br/>
068 [SB15_100_Cx] SB15m, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB10</h3>
069 [SB10_60] SB10i, LPF=60 Hz<br/>
070 [SB10_100] SB10i, LPF=100 Hz<br/>
071 [SB10_200] SB10i, LPF=200 Hz<br/>
<h3>SB6</h3>
072 [SB6_60] SB6i, LPF=60 Hz<br/>
073 [SB6_100] SB6i, LPF=100 Hz<br/>
074 [SB6_200] SB6i, LPF=200 Hz<br/>
<h3>KILO</h3>
075 [KILO] Kilo, LPF=100 Hz<br/>
<h3>SYVA</h3>
076 [SYVA] Syva, large bande<br/>
<h3>SYVA_LOW</h3>
077 [SYVA LOW_100] Syva Low (séparé), LPF=100 Hz<br/>
<h3>SYVA+LOW</h3>
078 [SYVA LOW SYVA] Syva & Syva Low (couplé)<br/>

<h3>SYVA_SUB</h3>
079 [SYVA SUB_100] Syva Sub, LPF=100 Hz<br/>
080 [SYVA SUB_200] Syva Sub, LPF=200 Hz, optimisé pour le preset [X4]<br/>
<h3>SOKA</h3>
081 [SOKA] Soka, large bande<br/>
082 [SOKA_60] Soka, limite basse fréquence et SPL max abaissés, conguration sur mur avec renfort
de grave séparé<br/>
083 [SOKA_200] Soka, limite basse fréquence et SPL max augmentés, conguration sur mur avec renfort
de grave couplé proche
<h3>X15HiQ</h3>
084 [X15] X15 HiQ, large bande<br/>
085 [X15_MO] X15 HiQ, large bande, retour de scène (monitor), basse latence
<h3>X12</h3>
086 [X12] X12, large bande<br/>
087 [X12_MO] X12, large bande, retour de scène (monitor), basse latence
<h3>X8</h3>
088 [X8] X8, large bande
089 [X8_MO] X8, large bande, retour de scène (monitor), basse latence
<h3>115XTHiQ</h3>
090 [HiQ_FI] 115XT HiQ, large bande, complément (fill)<br/>
091 [HiQ_FI_100] 115XT HiQ, HPF=100 Hz, complément (fill)<br/>
092 [HiQ_FR] 115XT HiQ, large bande, façade (FOH)<br/>
093 [HiQ_FR_100] 115XT HiQ, HPF=100 Hz, façade (FOH)<br/>
094 [HiQ_MO] 115XT HiQ, large bande, retour de scène (monitor)<br/>
095 [HiQ_MO_100] 115XT HiQ, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>12XTA</h3>
096 [12XTA_FI] 12XT active, large bande, complément (fill)<br/>
097 [12XTA_FI_100] 12XT active, HPF=100 Hz, complément (fill)<br/>
098 [12XTA_FR] 12XT active, large bande, façade (FOH)<br/>
099 [12XTA_FR_100] 12XT active, HPF=100 Hz, façade (FOH)<br/>
100 [12XTA_MO] 12XT active, large bande, retour de scène (monitor)<br/>
101 [12XTA_MO_100] 12XT active, HPF=100 Hz, retour de scène (monitor)<br/>

<h3>12XTP</h3>
102 [12XTP_FI] 12XT passive, large bande, complément (fill)<br/>
103 [12XTP_FI_100] 12XT passive, HPF=100 Hz, complément (fill)<br/>
104 [12XTP_FR] 12XT passive, large bande, façade (FOH)<br/>
105 [12XTP_FR_100] 12XT passive, HPF=100 Hz, façade (FOH)<br/>
106 [12XTP_MO] 12XT passive, large bande, retour de scène (monitor)<br/>
107 [12XTP_MO_100] 12XT passive, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>8XT</h3>
108 [8XT_FI] 8XT, large bande, complément (fill)<br/>
109 [8XT_FI_100] 8XT, HPF=100 Hz, complément (fill)<br/>
110 [8XT_FR] 8XT, large bande, façade (FOH)<br/>
111 [8XT_FR_100] 8XT, HPF=100 Hz, façade (FOH)<br/>
112 [8XT_MO] 8XT, large bande, retour de scène (monitor)<br/>
113 [8XT_MO_100] 8XT, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>5XT</h3>
114 [5XT] 5XT, large bande
115 [5XT_MO] 5XT, large bande, retour de scène (monitor), basse latence
<h3>X4</h3>
116 [X4] X4i, large bande
117 [X4_60] X4i, limite basse fréquence et SPL max abaissés, conguration sur mur avec renfort de
grave séparé<br/>
118 [X4_MO] X4i, large bande, retour de scène (monitor), basse latence
FLAT<br/>
119 [FLAT_LA4X] EQ réponse plate, protection minimisant les risques de saturation


  </div>
</template>

<script>
export default {
  name: "La4xAmpli"
};
</script>

<style>
@import "../../assets/style/stylepresets.css";
</style>
