<template>
  <div>
    <h3>LA2Xi</h3>
    <div>
      <a    href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA2Xi/ EN/Owner's%20Manual/LA2Xi_OM_EN.pdf" target="_blank">
              <img src="@/assets/img-amplis/la2xi-face.jpg" alt="" />
              <img src="@/assets/img-amplis/la2xi.jpg" alt="" />
      </a>
    </div>
    Librairie de presets LA2Xi 6.12
    <h3>KARA_II</h3>
    011 [KARA II 70] Kara II, large bande, réglage 70º sur ailettes mobiles<br/>
    012 [KARA II 90] Kara II, large bande, réglage 90º sur ailettes mobiles<br/>
    013 [KARA II 110] Kara II, large bande, réglage 110º sur ailettes mobiles<br/>
    014 [KARA II_FI] Kara II, HPF=100 Hz, complément (fill)<br/>
    015 [KARA II_MO] Kara II, large bande, retour de scène (monitor), basse latence<br/>
    016 [KARAIIDOWNK3] Kara II, délai optimisé en complément downfill pour K3
    <h3>KARA</h3>
    017 [KARA] Kara, large bande, façade (FOH)<br/>
    018 [KARA_FI] Kara, HPF=100 Hz, complément (fill)<br/>
    019 [KARADOWNK3] Kara, HPF=100 Hz, délai optimisé en complément downfill pour K3<br/>
    <h3>KIVA_II</h3>
    020 [KIVA II] Kiva II, large bande, façade (FOH)<br/>
    021 [KIVA II_FI] Kiva II, large bande, complément (fill)<br/>
    <h3>A15</h3>
    022 [A15] A15 Wide ou A15 Focus, large bande<br/>
    023 [A15_FI] A15 Wide ou A15 Focus, large bande, complément (fill)<br/>
    024 [A15_MO] A15 Wide ou A15 Focus, large bande, retour de scène (monitor), basse latence<br/>
    <h3>A10</h3>
    025 [A10] A10 Wide ou A10 Focus, large bande<br/>
    026 [A10_FI] A10 Wide ou A10 Focus, large bande, complément (fill)<br/>
    027 [A10_MO] A10 Wide ou A10 Focus, large bande, retour de scène (monitor), basse latence<br/>
    <h3>ARCS_WF</h3>
    028 [ARCS_WIFO] ARCS Wide ou ARCS Focus, large bande, façade (FOH)<br/>
    029 [ARCS_WIFO_FI] ARCS Wide ou ARCS Focus, large bande, complément (fill)<br/>
    <h3>KS28</h3>
    030 [KS28_60] KS28, LPF=60 Hz<br/>
    031 [KS28_100] KS28, LPF=100 Hz<br/>
    032 [KS28_60_C] KS28, LPF=60 Hz, cardioïde<br/>
    033 [KS28_100_C] KS28, LPF=100 Hz, cardioïde<br/>
    034 [KS28_60_Cx] KS28, LPF=60 Hz, cardioïde étendu<br/>
    035 [KS28_100_Cx] KS28, LPF=100 Hz, cardioïde étendu<br/>
    <h3>SB28</h3>
    036 [SB28_60] SB28, LPF=60 Hz<br/>
    037 [SB28_100] SB28, LPF=100 Hz<br/>
    038 [SB28_60_C] SB28, LPF=60 Hz, cardioïde<br/>
    039 [SB28_100_C] SB28, LPF=100 Hz, cardioïde<br/>
    040 [SB28_60_Cx] SB28, LPF=60 Hz, cardioïde étendu<br/>
    041 [SB28_100_Cx] SB28, LPF=100 Hz, cardioïde étendu<br/>
  <h3>KS21</h3>
    042 [KS21_60] KS21, LPF=60 Hz<br/>
    043 [KS21_100] KS21, LPF=100 Hz<br/>
    044 [KS21_60_C] KS21, LPF=60 Hz, cardioïde<br/>
    045 [KS21_100_C] KS21, LPF=100 Hz, cardioïde<br/>
    046 [KS21_60_Cx] KS21, LPF=60 Hz, cardioïde étendu<br/>
    047 [KS21_100_Cx] KS21, LPF=100 Hz, cardioïde étendu<br/>
    <h3>SB18</h3>
    048 [SB18_60] SB18, LPF=60 Hz<br/>
    049 [SB18_100] SB18, LPF=100 Hz<br/>
    050 [SB18_60_C] SB18, LPF=60 Hz, cardioïde<br/>
    051 [SB18_100_C] SB18, LPF=100 Hz, cardioïde<br/>
    052 [SB18_60_Cx] SB18, LPF=60 Hz, cardioïde étendu<br/>
    053 [SB18_100_Cx] SB18, LPF=100 Hz, cardioïde étendu<br/>
    <h3>SB15</h3>
    054 [SB15_100] SB15m, LPF=100 Hz<br/>
    055 [SB15_100_C] SB15m, LPF=100 Hz, cardioïde<br/>
    056 [SB15_100_Cx] SB15m, LPF=100 Hz, cardioïde étendu<br/>
   <h3> SB10</h3>
    057 [SB10_60] SB10i, LPF=60 Hz<br/>
    058 [SB10_100] SB10i, LPF=100 Hz<br/>
    059 [SB10_200] SB10i, LPF=200 Hz<br/>
    <h3>SB6</h3>
    060 [SB6_60] SB6i, LPF=60 Hz<br/>
    061 [SB6_100] SB6i, LPF=100 Hz<br/>
    062 [SB6_200] SB6i, LPF=200 Hz<br/>
   <h3> SYVA</h3>
    063 [SYVA] Syva, large bande<br/>
   <h3> SYVA_LOW</h3>
    064 [SYVA LOW_100] Syva Low (séparé), LPF=100 Hz
    SYVA+LOW<br/>
    065 [SYVA LOW SYVA] Syva & Syva Low (couplé)
    <h3>SYVA_SUB</h3>
    066 [SYVA SUB_100] Syva Sub, LPF=100 Hz<br/>
    067 [SYVA SUB_200] Syva Sub, LPF=200 Hz, optimisé pour le preset [X4]
    <h3>SOKA</h3>
    068 [SOKA] Soka, large bande<br/>
    069 [SOKA_60] Soka, limite basse fréquence et SPL max abaissés, coniguration sur mur avec renfort
    de grave séparé<br/>
    070 [SOKA_200] Soka, limite basse fréquence et SPL max augmentés, coniguration sur mur avec renfort
    de grave couplé proche<br/>
    <h3>X15HiQ</h3>
    071 [X15] X15 HiQ, large bande<br/>
    072 [X15_MO] X15 HiQ, large bande, retour de scène (monitor), basse latence
   <h3> X12</h3>
    073 [X12] X12, large bande<br/>
    074 [X12_MO] X12, large bande, retour de scène (monitor), basse latence
  <h3>  X8</h3>
    075 [X8] X8, large bande<br/>
    076 [X8_MO] X8, large bande, retour de scène (monitor), basse latence
   <h3> 5XT</h3>
    077 [5XT] 5XT, large bande<br/>
    078 [5XT_MO] 5XT, large bande, retour de scène (monitor), basse latence
    <h3>X4</h3>
    079 [X4] X4i, large bande<br/>
    080 [X4_60] X4i, limite basse fréquence et SPL max abaissés, coniguration sur mur avec renfort de
    grave séparé<br/>
    081 [X4_MO] X4i, large bande, retour de scène (monitor), basse latence
    <h3>FLAT</h3>
    082 [FLAT_LA2X] EQ réponse plate, protection minimisant les risques de saturation
    
  </div>
</template>

<script>
export default {
  name: "La2xiAmpli"
};
</script>

<style>
@import "../../assets/style/stylepresets.css";
</style>
