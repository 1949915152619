import { createRouter, createWebHistory } from "vue-router";

import AmplisLacoustics from "../views/AmplisLacoustics.vue";
import PresetsUsine from "../views/PresetsUsine.vue";
import Prealignement from "../views/Prealignement.vue";

const routes = [
  {
    path: "/",
    name: "Amplis",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AmplisLacoustics
  },
  {
    path: "/presets",
    name: "Presets",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PresetsUsine
  },
  {
    path: "/prealignement",
    name: "Prealignement",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Prealignement
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
});


export default router;
