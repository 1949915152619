<template>
  <div>
    <h3>LA12X</h3>
    <div>
      <a href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA12X/EN/Owner's%20Manual/LA12X_OM_EN.pdf" target="_blank">
      <img src="@/assets/img-amplis/la12x-face.jpg" alt="" />
      <img src="@/assets/img-amplis/la12x.jpg" alt="" />
      </a>
    </div>
    Librairie de presets LA12X 6.12
<h3>K1</h3>
011 [K1] K1, large bande<br/>
<h3>K2</h3>
012 [K2 70] K2, large bande, réglage 70º sur ailettes mobiles<br/>
013 [K2 90] K2, large bande, réglage 90º sur ailettes mobiles<br/>
014 [K2 110] K2, large bande, réglage 110º sur ailettes mobiles<br/>
<h3>K3</h3>
015 [K3 70] K3, large bande, réglage 70º sur ailettes mobiles<br/>
016 [K3 90] K3, large bande, réglage 90º sur ailettes mobiles<br/>
017 [K3 110] K3, large bande, réglage 110º sur ailettes mobiles<br/>
<h3>K1-SB</h3>
018 [K1SB_60] K1-SB, LPF=60 Hz, optimisé pour la conguration CONTOUR<br/>
019 [K1SB_X] K1-SB, LPF=200 Hz, optimisé pour la conguration THROW sur K1<br/>
020 [K1SB_X K2] K1-SB, LPF=200 Hz, optimisé pour la conguration THROW sur K2<br/>
<h3>KARA_II</h3>
021 [KARA II 70] Kara II, large bande, réglage 70º sur ailettes mobiles<br/>
022 [KARA II 90] Kara II, large bande, réglage 90º sur ailettes mobiles<br/>
023 [KARA II 110] Kara II, large bande, réglage 110º sur ailettes mobiles<br/>
024 [KARA II_FI] Kara II, HPF=100 Hz, complément (fill)<br/>
025 [KARA II_MO] Kara II, large bande, retour de scène (monitor), basse latence<br/>
026 [KARAIIDOWNK1] Kara II, délai optimisé en complément downfill pour K1<br/>
027 [KARAIIDOWNK2] Kara II, délai optimisé en complément downfill pour K2<br/>
028 [KARAIIDOWNK3] Kara II, délai optimisé en complément downfill pour K3<br/>
<h3>KARA</h3>
029 [KARA] Kara, large bande, façade (FOH)<br/>
030 [KARA_FI] Kara, HPF=100 Hz, complément (fill)<br/>
031 [KARADOWNK1] Kara, HPF=100 Hz, délai optimisé en complément downfill pour K1<br/>
032 [KARADOWNK2] Kara, HPF=100 Hz, délai optimisé en complément downfill pour K2<br/>
033 [KARADOWNK3] Kara, HPF=100 Hz, délai optimisé en complément downfill pour K3<br/>

<h3>ARCS_II</h3>
034 [ARCS II] ARCS II, large bande<br/>
<h3>A15</h3>
035 [A15] A15 Wide ou A15 Focus, large bande<br/>
036 [A15_FI] A15 Wide ou A15 Focus, large bande, complément (fill)<br/>
037 [A15_MO] A15 Wide ou A15 Focus, large bande, retour de scène (monitor), basse latence<br/>
<h3>A10</h3>
038 [A10] A10 Wide ou A10 Focus, large bande<br/>
039 [A10_FI] A10 Wide ou A10 Focus, large bande, complément (fill)<br/>
040 [A10_MO] A10 Wide ou A10 Focus, large bande, retour de scène (monitor), basse latence<br/>
<h3>ARCS_WF</h3>
041 [ARCS_WIFO] ARCS Wide ou ARCS Focus, large bande, façade (FOH)<br/>
042 [ARCS_WIFO_FI] ARCS Wide ou ARCS Focus, large bande, complément (fill)<br/>
<h3>KS28</h3>
043 [KS28_60] KS28, LPF=60 Hz<br/>
044 [KS28_100] KS28, LPF=100 Hz<br/>
045 [KS28_60_C] KS28, LPF=60 Hz, cardioïde<br/>
046 [KS28_100_C] KS28, LPF=100 Hz, cardioïde<br/>
047 [KS28_60_Cx] KS28, LPF=60 Hz, cardioïde étendu<br/>
048 [KS28_100_Cx] KS28, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB28</h3>
049 [SB28_60] SB28, LPF=60 Hz<br/>
050 [SB28_100] SB28, LPF=100 Hz<br/>
051 [SB28_60_C] SB28, LPF=60 Hz, cardioïde<br/>
052 [SB28_100_C] SB28, LPF=100 Hz, cardioïde<br/>
053 [SB28_60_Cx] SB28, LPF=60 Hz, cardioïde étendu<br/>
054 [SB28_100_Cx] SB28, LPF=100 Hz, cardioïde étendu<br/>
<h3>KS21</h3>
055 [KS21_60] KS21, LPF=60 Hz<br/>
056 [KS21_100] KS21, LPF=100 Hz<br/>
057 [KS21_60_C] KS21, LPF=60 Hz, cardioïde<br/>
058 [KS21_100_C] KS21, LPF=100 Hz, cardioïde<br/>
059 [KS21_60_Cx] KS21, LPF=60 Hz, cardioïde étendu<br/>
060 [KS21_100_Cx] KS21, LPF=100 Hz, cardioïde étendu<br/>
32 Guide des Presets manuel du propriétaire (FR) version 21.1
Librairies de presets embarquées
<h3>SB18</h3>
061 [SB18_60] SB18, LPF=60 Hz<br/>
062 [SB18_100] SB18, LPF=100 Hz<br/>
063 [SB18_60_C] SB18, LPF=60 Hz, cardioïde<br/>
064 [SB18_100_C] SB18, LPF=100 Hz, cardioïde<br/>
065 [SB18_60_Cx] SB18, LPF=60 Hz, cardioïde étendu<br/>
066 [SB18_100_Cx] SB18, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB15</h3>
067 [SB15_100] SB15m, LPF=100 Hz<br/>
068 [SB15_100_C] SB15m, LPF=100 Hz, cardioïde<br/>
069 [SB15_100_Cx] SB15m, LPF=100 Hz, cardioïde étendu<br/>
<h3>SB10</h3>
070 [SB10_60] SB10i, LPF=60 Hz<br/>
071 [SB10_100] SB10i, LPF=100 Hz<br/>
072 [SB10_200] SB10i, LPF=200 Hz<br/>
<h3>SB6</h3>
073 [SB6_60] SB6i, LPF=60 Hz<br/>
074 [SB6_100] SB6i, LPF=100 Hz<br/>
075 [SB6_200] SB6i, LPF=200 Hz<br/>
<h3>KIVA_II</h3>
076 [KIVA II] Kiva II, large bande, façade (FOH)<br/>
077 [KIVA II_FI] Kiva II, large bande, complément (fill)<br/>
<h3>KIVA</h3>
078 [KIVA] Kiva, large bande, façade (FOH)<br/>
079 [KIVA_FI] Kiva, large bande, complément (fill)<br/>
<h3>SB15KIVA</h3>
080 [KIVA_SB15] Kiva & SB15m, X-OVER=100 Hz, large bande, façade (FOH)<br/>
<h3>SYVA</h3>
081 [SYVA] Syva, large bande<br/>
<h3>SYVA_LOW</h3>
082 [SYVA LOW_100] Syva Low (séparé), LPF=100 Hz<br/>
<h3>SYVA+LOW</h3>
083 [SYVA LOW SYVA] Syva & Syva Low (couplé)<br/>
<h3>SYVA_SUB</h3>
084 [SYVA SUB_100] Syva Sub, LPF=100 Hz<br/>
085 [SYVA SUB_200] Syva Sub, LPF=200 Hz, optimisé pour le preset [X4]<br/>
<h3>SOKA</h3>
086 [SOKA] Soka, large bande<br/>
087 [SOKA_60] Soka, limite basse fréquence et SPL max abaissés, conguration sur mur avec renfort
de grave séparé<br/>
088 [SOKA_200] Soka, limite basse fréquence et SPL max augmentés, conguration sur mur avec renfort
de grave couplé proche<br/>
<h3>X15HiQ</h3>
089 [X15] X15 HiQ, large bande<br/>
090 [X15_MO] X15 HiQ, large bande, retour de scène (monitor), basse latence<br/>
<h3>X12</h3>
091 [X12] X12, large bande<br/>
092 [X12_MO] X12, large bande, retour de scène (monitor), basse latence<br/>
<h3>X8</h3>
093 [X8] X8, large bande<br/>
094 [X8_MO] X8, large bande, retour de scène (monitor), basse latence<br/>
<h3>115XTHiQ</h3>
095 [HiQ_FI] 115XT HiQ, large bande, complément (fill)<br/>
096 [HiQ_FI_100] 115XT HiQ, HPF=100 Hz, complément (fill)<br/>
097 [HiQ_FR] 115XT HiQ, large bande, façade (FOH)<br/>
098 [HiQ_FR_100] 115XT HiQ, HPF=100 Hz, façade (FOH)<br/>
099 [HiQ_MO] 115XT HiQ, large bande, retour de scène (monitor)<br/>
100 [HiQ_MO_100] 115XT HiQ, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>12XTA</h3>
101 [12XTA_FI] 12XT active, large bande, complément (fill)<br/>
102 [12XTA_FI_100] 12XT active, HPF=100 Hz, complément (fill)<br/>
103 [12XTA_FR] 12XT active, large bande, façade (FOH)<br/>
104 [12XTA_FR_100] 12XT active, HPF=100 Hz, façade (FOH)<br/>
105 [12XTA_MO] 12XT active, large bande, retour de scène (monitor)<br/>
106 [12XTA_MO_100] 12XT active, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>12XTP</h3>
107 [12XTP_FI] 12XT passive, large bande, complément (fill)<br/>
108 [12XTP_FI_100] 12XT passive, HPF=100 Hz, complément (fill)<br/>
109 [12XTP_FR] 12XT passive, large bande, façade (FOH)<br/>
110 [12XTP_FR_100] 12XT passive, HPF=100 Hz, façade (FOH)<br/>
111 [12XTP_MO] 12XT passive, large bande, retour de scène (monitor)<br/>
112 [12XTP_MO_100] 12XT passive, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>8XT</h3>
113 [8XT_FI] 8XT, large bande, complément (fill)<br/>
114 [8XT_FI_100] 8XT, HPF=100 Hz, complément (fill)<br/>
115 [8XT_FR] 8XT, large bande, façade (FOH)<br/>
116 [8XT_FR_100] 8XT, HPF=100 Hz, façade (FOH)<br/>
117 [8XT_MO] 8XT, large bande, retour de scène (monitor)<br/>
118 [8XT_MO_100] 8XT, HPF=100 Hz, retour de scène (monitor)<br/>
<h3>5XT</h3>
119 [5XT] 5XT, large bande<br/>
120 [5XT_MO] 5XT, large bande, retour de scène (monitor), basse latence<br/>
<h3>X4</h3>
121 [X4] X4i, large bande<br/>
122 [X4_60] X4i, limite basse fréquence et SPL max abaissés, conguration sur mur avec renfort de
grave séparé<br/>
123 [X4_MO] X4i, large bande, retour de scène (monitor), basse latence<br/>
<h3>FLAT</h3>
124 [FLAT_LA12X] EQ réponse plate, protection minimisant les risques de saturation<br/>

  </div>
</template>

<script>
export default {
  name: "La12xAmpli"
};
</script>

<style>
@import "../../assets/style/stylepresets.css";
</style>
