<template>
  <div class="container">
    <div class="header">
      <label>Librairie de presets 7.6</label>
      <button @click="show('isinfo')">Info</button>
      
    </div>

    <div class="button-container">
      <button @click="show('la2xi')">LA2Xi</button
      ><button @click="show('la4')">LA4</button
      ><button @click="show('la4x')">LA4X</button
      ><button @click="show('la8')">LA8</button
      ><button @click="show('la12x')">LA12X</button>
      <button @click="show('la7x')">LA7.16i</button>
    </div>
    <Infos v-if="buttonAmpli == 'isinfo'" />
  </div>
  <div class="pdfcardio">
    <h3>
      <a
        href="https://www.l-acoustics.com/documentation/GLOBAL/EN/Guide%20des%20Presets/preset_guide_FR.pdf"
        target="_blank"
      >
        Presets version PDF en Français
      </a>
    </h3>
    <h3>
      <a
        href="https://www.l-acoustics.com/documentation/GLOBAL/EN/Subwoofer%20Configuration%20TB/Cardioid_configurations_TB_EN.pdf"
        target="_blank"
      >
        Cardioid C et Cardioid Cx
      </a>
    </h3>
  </div>

  <div class="presets">
    <La4 v-if="buttonAmpli == 'la4'" />
    <La4x v-if="buttonAmpli == 'la4x'" />
    <La8 v-if="buttonAmpli == 'la8'" />
    <La12x v-if="buttonAmpli == 'la12x'" />
    <La2xi v-if="buttonAmpli == 'la2xi'" />
    <La7x v-if="buttonAmpli == 'la7x'" />
  </div>
</template>

<script>
import Infos from "@/components/presets 6-12/InfosAmplis.vue";
import La4x from "@/components/presets 6-12/La4xAmpli.vue";
import La12x from "@/components/presets 6-12/La12xAmpli.vue";
import La8 from "@/components/presets 6-12/La8Ampli.vue";
import La4 from "@/components/presets 6-12/La4Ampli.vue";
import La2xi from "@/components/presets 6-12/La2xiAmpli.vue";
import La7x from "@/components/presets 6-12/La7xAmpli.vue";

export default {
  // eslint-disable-next-line
  name: "PresetsUsine",
  components: { Infos, La4x, La8, La4, La12x, La2xi, La7x },
  data() {
    return {
      buttonAmpli: "la4x"
    };
  },
  methods: {
    show(param) {
      this.buttonAmpli = param;
      console.log(param);
    }
  }
};
</script>

<style scoped>
.container {
  margin: 20px auto;

  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: center;
  align-items: center; */
}

.header {
  margin: 0px 0px 15px 0px;
}

.presets {
  padding: 20px 10px;
  text-align: left;
  max-width: 1200px;
  margin: auto;
}
.presets img {
  max-width: 350px;
}
.button-container {
  justify-content: center;
}
button {
  flex-direction: row;
  margin: 10px 10px;
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
}
button:active {
  background-color: #66dd9a;
}
.pdfcardio {
  text-align: left;
}
.pdfcardio h3:before {
  content: "\2606";
}
@media only screen and (min-width: 893px) {
  .presets {
    display: flex;
    justify-content: space-around;
    margin: auto;
  }
}
</style>
