<template>
  <div>
    <h3>LA7.16</h3>
    <div>
      <a href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA7.16/EN/System%20Spec%20Sheet/LA7.16_SPS_EN_1.0.pdf" target="_blank">
      <img src="@/assets/img-amplis/la7x-face.png" alt="" />
      <img src="@/assets/img-amplis/la7x.png" alt=""/>
      </a>
    </div>
    Librairie de layouts LA7.16i 7.6
    <h3>K2</h3>
    01 [K2 70] K2, large bande, réglage 70º sur ailettes mobiles<br/>
    02 [K2 90] K2, large bande, réglage 90º sur ailettes mobiles<br/>
    03 [K2 110] K2, large bande, réglage 110º sur ailettes mobiles<br/>
    <h3>K3</h3>
    04 [K3 70] K3, large bande, réglage 70º sur ailettes mobiles<br/>
    05 [K3 90] K3, large bande, réglage 90º sur ailettes mobiles<br/>
    06 [K3 110] K3, large bande, réglage 110º sur ailettes mobiles<br/>
    <h3>KARA II</h3>
    07 [KARA II 70] Kara II, large bande, réglage 70º sur ailettes mobiles<br/>
    08 [KARA II 90] Kara II, large bande, réglage 90º sur ailettes mobiles<br/>
    09 [KARA II 110] Kara II, large bande, réglage 110º sur ailettes mobiles<br/>
    010 [KARA II_FI] Kara II, HPF=100 Hz, complément (fill)<br/>
    011 [KARA II_MO] Kara II, large bande, retour de scène (monitor), basse latence<br/>
    012 [KARAIIDOWNK1] Kara II, délai optimisé en complément downfill pour K1<br/>
    013 [KARAIIDOWNK2] Kara II, délai optimisé en complément downfill pour K2<br/>
    014 [KARAIIDOWNK3] Kara II, délai optimisé en complément downfill pour K3<br/>
    <h3>A15</h3>
    015 [A15] A15 Wide ou A15 Focus, large bande<br/>
    016 [A15_FI] A15 Wide ou A15 Focus, large bande, complément (fill)<br/>
    017 [A15_MO] A15 Wide ou A15 Focus, large bande, retour de scène (monitor), basse latence<br/>
    <h3>A10</h3>
    018 [A10] A10 Wide ou A10 Focus, large bande<br/>
    019 [A10_FI] A10 Wide ou A10 Focus, large bande, complément (fill)<br/>
    020 [A10_MO] A10 Wide ou A10 Focus, large bande, retour de scène (monitor), basse latence<br/>
    <h3>KS21</h3>
    021 [KS21_60] KS21, LPF=60 Hz<br/>
    022 [KS21_100] KS21, LPF=100 Hz<br/>
    <h3>KS21_C</h3>
    023 [KS21_60_C] KS21, LPF=60 Hz, cardioïde<br/>
    024 [KS21_100_C] KS21, LPF=100 Hz, cardioïde<br/>
    025 [KS21_60_Cx] KS21, LPF=60 Hz, cardioïde étendu<br/>
    026 [KS21_100_Cx] KS21, LPF=100 Hz, cardioïde étendu<br/>
    <h3>SB18</h3>
    027 [SB18_60] SB18, LPF=60 Hz<br/>
    028 [SB18_100] SB18, LPF=100 Hz<br/>
    <h3>SB18_C</h3>
    029 [SB18_60_C] SB18, LPF=60 Hz, cardioïde<br/>
    030 [SB18_100_C] SB18, LPF=100 Hz, cardioïde<br/>
    031 [SB18_60_Cx] SB18, LPF=60 Hz, cardioïde étendu<br/>
    032 [SB18_100_Cx] SB18, LPF=100 Hz, cardioïde étendu<br/>
    <h3>SB15</h3>
    033 [SB15_100] SB15m, LPF=100 Hz<br/>
    <h3>SB15_C</h3>
    034 [SB15_100_C] SB15m, LPF=100 Hz, cardioïde<br/>
    035 [SB15_100_Cx] SB15m, LPF=100 Hz, cardioïde étendu<br/>
    <h3>SB10</h3>
    036 [SB10_60] SB10i, LPF=60 Hz<br/>
    037 [SB10_100] SB10i, LPF=100 Hz<br/>
    038 [SB10_200] SB10i, LPF=200 Hz<br/>
    <h3>SB6</h3>
    039 [SB6_60] SB6i, LPF=60 Hz<br/>
    040 [SB6_100] SB6i, LPF=100 Hz<br/>
    041 [SB6_200] SB6i, LPF=200 Hz<br/>
    <h3>KIVA II</h3>
    042 [KIVA II] Kiva II, large bande, façade (FOH)<br/>
    043 [KIVA II_FI] Kiva II, large bande, complément (fill)<br/>
    <h3>SYVA</h3>
    044 [SYVA] Syva, large bande
    <h3>SYVA LOW</h3>
    045 [SYVA LOW_100] Syva Low (séparé), LPF=100 Hz<br/>
    046 [SYVA LOW SYVA] Syva & Syva Low (couplé)<br/>
    <h3>SYVA SUB</h3>
    047 [SYVA SUB_100] Syva Sub, LPF=100 Hz<br/>
    048 [SYVA SUB_200] Syva Sub, LPF=200 Hz, optimisé pour le preset [X4]<br/>
    <h3>SOKA</h3>
    049 [SOKA] Soka, large bande<br/>
    050 [SOKA_60] Soka, limite basse fréquence et SPL max abaissés, coniguration sur mur avec renfort
    de grave séparé<br/>
    051 [SOKA_200] Soka, limite basse fréquence et SPL max augmentés, coniguration sur mur avec renfort
    de grave couplé proche<br/>
    <h3>X15</h3>
    052 [X15] X15 HiQ, large bande<br/>
    053 [X15_MO] X15 HiQ, large bande, retour de scène (monitor), basse latence<br/>
   <h3> X12</h3>
    054 [X12] X12, large bande<br/>
    055 [X12_MO] X12, large bande, retour de scène (monitor), basse latence<br/>
    <h3>X8</h3>
    056 [X8] X8, large bande<br/>
    057 [X8_MO] X8, large bande, retour de scène (monitor), basse latence<br/>
    <h3>5XT</h3>
    058 [5XT] 5XT, large bande<br/>
    059 [5XT_MO] 5XT, large bande, retour de scène (monitor), basse latence<br/>
    <h3>X4</h3>
    060 [X4] X4i, large bande<br/>
    061 [X4_60] X4i, limite basse fréquence et SPL max abaissés, coniguration sur mur avec renfort de
    grave séparé<br/>
    062 [X4_MO] X4i, large bande, retour de scène (monitor), basse latence
    <h3>FLAT_LA7.16_8R</h3>
    063 [FLAT_LA7.16_8R] EQ réponse plate, protection minimisant les risques de saturation. Utiliser pour des
    charges de 8 Ω ou plus.
    <h3>FLAT_LA7.16_4R</h3>
    064 [FLAT_LA7.16_4R] EQ réponse plate, protection minimisant les risques de saturation ou de surintensité.
    Utiliser pour des charges comprises entre 4 Ω et 8 Ω.
  </div>
</template>

<script>
export default {
  name: "La7Ampli"
};
</script>

<style>
@import "../../assets/style/stylepresets.css";
</style>
