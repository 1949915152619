<template>
<div class="contact">
    <a href="mailto:franck.richard@cinod.fr">@Contact</a>
    <a href="https://www.cinod.fr/monplandescene-2/">Monplandescene</a>
    <a href="https://cinod.fr">Cinod</a>
</div>

  <button v-if="isHiden" v-on:click="change(isHiden)">
    Pour info
  </button>
  
  <button v-if="!isHiden" v-on:click="change(isHiden)">Fermer</button>
  <Impedance v-if="!isHiden" />
  

  <div v-if="isHiden" class="container">
    <div class="tab">
      <p>Choisissez une série puis un modèle:</p>
      <div class="button-container">
        <div v-for="typeSerie in typeSeries" :key="typeSerie.id">
          <button v-on:click="searchInput(typeSerie.name)" class="serie">
            {{ typeSerie.name }}
          </button>
        </div>
        <button @click="cancelSearch" class="serie">All</button>
      </div>

      <!-- bigin list -->
      <table>
        <tr v-if="search.length !== 0">
          <td>Ampli LA ....</td>
          <td>4</td>
          <td>4x</td>
          <td>8</td>
          <td>12x</td>
          <td>2xi</td>
          <td>7</td>
        </tr>

        <tr v-for="enceinte in search" :key="enceinte.id">
          <td @click="detailEnceinte(enceinte)">
            {{ enceinte.name }}
          </td>
          <td class="nb-amplis">{{ enceinte.LA4 }}</td>
          <td class="nb-amplis">{{ enceinte.LA4X }}</td>
          <td class="nb-amplis">{{ enceinte.LA8 }}</td>
          <td class="nb-amplis">{{ enceinte.LA12X }}</td>
          <td class="nb-amplis">{{ enceinte.LA2Xise }}</td>
          <td class="nb-amplis">{{ enceinte.LA7 }}</td>
        </tr>
      </table>
    </div>
    <!-- begin card detail -->
    <div class="details" v-if="enceinteDetail.id">
      <div class="title-img">
        <div>
          <h1 class="detail-nom">
            <a :href="getUrl(enceinteDetail.link)" target="_blank">
              {{ enceinteDetail.name }}</a
            >
          </h1>
          <div>
            <div class="infossupp">
              <p>Système: {{ enceinteDetail.actif }}</p>

              <p>BP: {{ enceinteDetail.bp }}</p>

              <div>
                <p>Poids: {{ enceinteDetail.poids }} kg</p>
                <p>max SPL: {{ enceinteDetail.spl }} dB</p>
                <p>Directivity: {{ enceinteDetail.Directivity }} </p>
                <button
                  v-if="enceinteDetail.picture_2"
                  @click="vpicture = !vpicture"
                >
                  Plus d'images
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <img :src="getImgUrl(enceinteDetail.picture)" alt="" />
          <div class="link">
            <p v-if="enceinteDetail.rigging">
              <a :href="getUrl(enceinteDetail.rigging)" target="_blank">
                Rigging</a
              >
            </p>
            <p v-if="enceinteDetail.link">
              <a :href="getUrl(enceinteDetail.link)" target="_blank"> Manuel</a>
            </p>
          </div>
        </div>
      </div>
      <div class="plusimages" v-if="vpicture">
        <img :src="getImgUrl(enceinteDetail.picture_2)" alt="" />
      </div>
      <div class="amplis_container">
        <div class="row">
          <h1>
            <a
              href="https://www.cinod.fr/wordpress/wp-content/old-lacoustics/LA4_UM_EN.pdf
              "
              target="_blank"
            >
              LA4
            </a>
          </h1>

          <h1>{{ enceinteDetail.LA4_s + " / " + enceinteDetail.LA4 }}</h1>
        </div>
        <div class="row">
          <h1>
            <a
              href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA4X/EN/Owner's%20Manual/LA4X_OM_EN.pdf"
              target="_blank"
            >
              LA4x
            </a>
          </h1>

          <h1>{{ enceinteDetail.LA4X_s + " / " + enceinteDetail.LA4X }}</h1>
        </div>
        <div class="row">
          <h1>
            <a
              href="https://www.cinod.fr/wordpress/wp-content/old-lacoustics/LA8_OM_EN.pdf"
              target="_blank"
            >
              LA8
            </a>
          </h1>

          <h1>{{ enceinteDetail.LA8_s + " /  " + enceinteDetail.LA8 }}</h1>
        </div>
        <div class="row">
          <h1>
            <a
              href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA12X/EN/Owner's%20Manual/LA12X_OM_EN.pdf"
              target="_blank"
            >
              LA12X
            </a>
          </h1>

          <h1>{{ enceinteDetail.LA12X_s + " / " + enceinteDetail.LA12X }}</h1>
        </div>
        <div class="row">
          <h2>
            <a
              href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA2Xi/EN/Owner's%20Manual/LA2Xi_OM_EN.pdf"
              target="_blank"
            >
              LA2xi se
            </a>
          </h2>

          <h1>
            {{ enceinteDetail.LA2Xise_s + " / " + enceinteDetail.LA2Xise }}
          </h1>
        </div>
        <div class="row">
          <h2>
            <a
              href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA2Xi/EN/Owner's%20Manual/LA2Xi_OM_EN.pdf"
              target="_blank"
              >LA2xi BTL
            </a>
          </h2>

          <h1>
            {{ enceinteDetail.LA2Xibtl_s + " / " + enceinteDetail.LA2Xibtl }}
          </h1>
        </div>
        <div class="row">
          <h1>
            <a
              href="https://www.l-acoustics.com/documentation/ELECTRONICS/LA7.16/EN/System%20Spec%20Sheet/LA7.16_SPS_EN_1.0.pdf" target="_blank"
              >LA7.16
            </a>
          </h1>

          <h1>
            {{ enceinteDetail.LA7_s + " / " + enceinteDetail.LA7 }}
          </h1>
        </div>
      </div>

      <p>{{ enceinteDetail.remarques }}</p>
      <p class="sortietotal">*par sortie / total</p>
    </div>

    <h1 v-if="search.length == 0">Désolé aucun résultat</h1>
  </div>
</template>

<script>
import json from "../../public/enceintes.json";
import Impedance from "@/components/Impedance.vue";

export default {
  name: "AmplisLacoustics",
  components: { Impedance },
  data() {
    return {
      imagehp: "@/assets/img-hp/lacousticsX4i.jpg",
      enceintes: json.enceintes,
      enceinteDetail: "",
      // inputType: "",
      selectedSerie: "K",
      searchkey: "",
      vpicture: "",
      isHiden: true,
      serieList: [],

      typeSeries: [
        { name: "A", id: "1" },
        { name: "X", id: "2" },
        { name: "SUB", id: "3" },
        { name: "K", id: "4" },
        { name: "SY", id: "5" },
        { name: "old", id: "6" }
      ]
    };
  },
  computed: {
    search() {
      
       if (!this.enceintes) {
      return []; // Ou une autre valeur par défaut 
    }
    
    return this.enceintes.filter(enceinte => {
      return (
         enceinte.name &&
        enceinte.name.toUpperCase().includes(this.searchkey.toUpperCase()) &&
        enceinte.serie &&
        enceinte.serie.includes(this.selectedSerie)
      );
    });
    }
  },
  methods: {
    searchInput(param) {
      this.selectedSerie = param;
    },
    detailEnceinte(detail) {
      this.enceinteDetail = detail;
    },
    cancelSearch() {
      this.searchKey = "";
      this.selectedSerie = "";
    },
    getUrl(pageweb) {
      return pageweb;
    },
    getImgUrl(pic) {
      return require("../assets/imghp/dessins/" + pic);
      // return "../assets/imghp/dessins/" + pic;
    },
    change(data) {
      this.isHiden = !data;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #3d454d;
}
a:hover {
  text-decoration: underline;
  color: var(--colorLA);
}

.amplis_container {
  margin-top: 15px;
}

.amplis_container {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.button-container {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}
.button-container button {
  background: white;
  border: 1px solid grey;
  padding: 4px 6px;
}
.button-container button:hover {
  background: rgb(36, 194, 15);
}
.button-container button:active {
  background: rgb(36, 194, 15);
}
button {
  background: var(--color1);
  padding: 4px 6px;
  margin: 10px auto;
  font-size: 1rem;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 375px; */

  margin: auto;
}
.contact {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.details {
  border-radius: 20px;
  width: 365px;
  border-collapse: separate;
  -webkit-box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  margin: 20px 0px;
  padding: 10px 20px;
  border: 1px solid;
}
.detail-nom {
  border-radius: 20px;
  width: 200px;
  border-collapse: separate;
  -webkit-box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  margin: 20px 0px;
  padding: 10px 20px;
  border: 1px solid;
}
img {
  width: 120px;
  height: auto;
  object-fit: contain;
}

input[type="radio"] {
  display: none;
}
input[type="radio"]:checked + label {
  background: #66dd9a;
  transform: scale(1.1);
}
label {
  cursor: pointer;
  border: 1px solid black;
  padding: 3px 6px;
  margin: 0.5rem;
}
.link p:before {
  content: "\2605";
}
.infossupp {
  text-align: left;
  /* line-height: 0.9rem; */
  /* margin-top: 5px; */
}

.infossupp p {
  /* padding-top: 0.1rem; */
}
.infossupp button {
  background: var(--colorLA);
  font-size: 0.7rem;
  color: white;
  border: grey;
  /* word-wrap: normal; */
}
.row {
  display: flex;
  direction: row;
  margin: 0px;
  padding: 0px;
  justify-content: space-between;
}
.plusimages img {
  width: 100%;
}

.row h4 {
  padding-top: 10px;
}

.tab {
  /* width: 365px; */
  margin: 1rem 0rem;
}
.tab p {
  margin: 10px;
}
.tab h3 {
  margin: 10px;
}

ul {
  display: flex;
  padding: 0px;
  list-style-type: none;
}
.radio-container {
  width: 350px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.serie {
  background: var(--color1);
  border-radius: 0.2rem;
  margin: 0.5rem 0.5rem 1rem 0.5rem;
  padding: 0.5rem;
}
.sortietotal {
  font-size: 12px;
  text-align: left;
}

table {
  /* margin: auto; */
  width: 365px !important;
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.75);
  border-spacing: 1px !important;
  border-radius: 0.5rem;
  margin: 20px 0px 30px 0px;
  border: solid 1px;
  border-spacing: 0px;
  text-align: center !important;
}
th {
  height: 20px;
  padding: 2px;
}
td {
  padding: 3px !important;
}
td:first-child {
  text-align: left;
  /* width: 115px !important; */
  padding: 3px 2px 0px 10px;
  font-weight: 700;
  font-size: 1.2em;
  cursor: pointer;
  /* text-overflow: hidden; */
  text-align: nowrap;
  width: 9rem;
}
td:first-child:hover {
  background-color: #66dd9a;
}
tr:first-child {
  background-color: var(--colorLA);
  color: white;
}
tr:nth-child(even) {
  background: rgb(232 232 234);
}
td {
  border-radius: 0.5rem;
}
tr:nth-child(odd) {
  /* background: #fff; */
}
/* .nb-amplis {
      text-align: center;
    } */

.title-img {
  display: flex;
  direction: row;
  justify-content: space-between;
}
.title-img img {
  filter: drop-shadow(10px 10px 5px #b8b8b8);
  -webkit-filter: drop-shadow(10px 10px 5px #b8b8b8);
  -moz-filter: drop-shadow(10px 10px 5px #b8b8b8);
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 768px) {
  .details {
    margin: 20px;
    width: 350px;
  }
  .container {
    display: flex;
    margin: auto;
    max-width: 1000px;
    flex-direction: row;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease-out;
}

.fade-leave-to {
  opacity: 0;
}
</style>
